import { render, staticRenderFns } from "./not-found.html?vue&type=template&id=b2cd150e&scoped=true&"
import script from "./not-found.js?vue&type=script&lang=js&"
export * from "./not-found.js?vue&type=script&lang=js&"
import style0 from "./not-found.scss?vue&type=style&index=0&id=b2cd150e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2cd150e",
  null
  
)

export default component.exports